












import Vue from 'vue';
import { extractComponents } from '@vsf-enterprise/amplience';

interface RenderContent {
  componentName: string
  props?: {}
}

export default Vue.extend({
  name: 'RenderContent',
  props: {
    content: {
      type: [Array, Object],
    },
  },
  computed: {
    components(): RenderContent[] {
      return extractComponents(this.content);
    },
  },
});
