//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { SfMenuItem, SfModal } from '@storefront-ui/vue'
import { useUiState } from '~/composables'
import useCmsLayout from '~/composables/useCmsLayout'

export default {
  name: 'CmsHeaderNavigation',
  components: {
    SfMenuItem,
    SfModal,
  },
  setup() {
    const { isMobileMenuOpen, toggleMobileMenu } = useUiState()
    const { header } = useCmsLayout()

    return {
      header,
      isMobileMenuOpen,
      toggleMobileMenu,
    }
  },
}
